const moment = require("moment");
document.addEventListener('DOMContentLoaded', () => {
  $('#add_salary').tooltip({
    template: '<div class="tooltip" role="tooltip">\
                <div class="tooltip-arrow"></div>\
                <div class="tooltip-inner add-salary-btn-tooltip"><i class="fas fa-info-circle text-white"></i>\</div>\
               </div>' })
  $('#add_base_salary').on('shown.bs.modal', () => {
    $('[data-toggle="tooltip"]').tooltip('hide');
  })
});

$(document).on('ajax:error, ajax:success', '#salaries_histories_form', (e) => {
  const header_message = "<p class='text-danger px-4 pt-4'> <span class='fas fa-times-circle fa-lg mx-2'>\
                          </span> <strong>Some Employees salaries are not saved. The following issues were found:</strong>\
                          <button class='close' type='button' id='close-errors-alert'> \
                          <span aria-hidden='true'>×</span> \
                          <span class='sr-only'>Close</span> \
                          </button> \
                        </p>";

  const {
    errors
  } = e.detail[0];
  const {
    saved_employees_list
  } = e.detail[0];
  const {
    created_by_hr
  } = e.detail[0];

  if (errors.length) {
    $('.alert-container').removeClass('d-none');
    append_errors_inside_modal(errors, header_message, $('#ajax_errors'));
  }
  if (saved_employees_list?.length) {
    $('.alert-container').removeClass('d-none');
    remove_employees_from_list($('#ajax_success'), saved_employees_list, created_by_hr);
    if (errors.length === 0) {
      $('#add_base_salary').modal('toggle');
      remove_employees_from_list($('.alert-container'), saved_employees_list, created_by_hr);
      if (($('#all_employees > div').length) === 0) {
        $('#add_base_salary_box').html('<div class="p p-4">No new salary of any employee required.</div>');
      }
    }
  }
});

const remove_employees_from_list = (element, saved_employees_list, created_by_hr) => {
  if (saved_employees_list.length) {
    let employees_name = '';
    for (let employee of Array.from(saved_employees_list)) {
      $('#employee_' + employee.id).remove()
      employees_name += `<div class='text-success px-5'><ul><li> ${employee.name} </li></ul> </div>`;
      $("#employee_" + `${employee.id}`).remove();
    }
    let header_success_message = ''
    if(created_by_hr){
      header_success_message = "<p class='text-success  px-4 pt-4'> <span class='fas fa-check-circle fa-lg mx-2'>\
                                </span><strong>Request successfully created for:</strong>\
                                <button class='close' data-dismiss='alert' type='button' id='close-success-alert'> \
                                <span aria-hidden='true'>×</span> \
                                <span class='sr-only'>Close</span> \
                                </button> \
                                </p>";
    } else {
      header_success_message = "<p class='text-success  px-4 pt-4'> <span class='fas fa-check-circle fa-lg mx-2'>\
                                </span><strong>Salary successfully added for:</strong>\
                                <button class='close' data-dismiss='alert' type='button' id='close-success-alert'> \
                                <span aria-hidden='true'>×</span> \
                                <span class='sr-only'>Close</span> \
                                </button> \
                                </p>";
    }

    append_success_message(employees_name, header_success_message, element);
    element.removeClass('d-none');
    $(".alert-success").fadeTo(5000, 1000).slideUp(1000, function() {
      $(".alert-success").slideUp(1000);
    });

  }
};

$(document).on('click', '#add_salary', (e) => {
  $('.alert-container').addClass('d-none');
  $('#base_salary_submit').addClass('disabled');
});

$(document).on('click', '#fetch-data-btn', (e) => {
  $(e.target).prop('disabled', true);
  $.ajax({ url: "/fetch_external_data" });
})

$(document).on('input', '.base_salary_input', (e) => {
  let sum = 0;
  for (let input of Array.from($('.base_salary_input'))) {
    sum += parseInt(input.value) || 0;
    if (sum > 0) { break; }
  }
  if (sum) {
    $('#base_salary_submit').removeClass('disabled');
  } else {
    $('#base_salary_submit').addClass('disabled');
  }
});

$(document).on('click', '.fa-calendar-alt', (e) => {
  $(e.target).parent().find('#datepicker-fields').trigger('focus');
});

$(document).on('click', '#close-errors-alert', (e) => {
  $('#ajax_errors').addClass('d-none');
});

$(document).on('click', '#close-success-alert', (e) => {
  $('#ajax_success').addClass('d-none');
});

$(document).on('click', '#base_salary_submit', (e) => {
  $('#add_base_salary').scrollTop(0);
});

$(document).on('shown.bs.modal', '.modal', (e) => {
  $(document).on('dp.change', '.base-salary-date-picker', (e) => {
    let start_date = new Date( Date.parse(e.currentTarget.value) );
    let end_date = start_date.setFullYear(start_date.getFullYear() + 1);
    let end_date_selector = e.target.classList[4].replace('start-date', 'end-date')
    $(e.target).closest('.form-group').find(`.${end_date_selector}`).val(moment(end_date).format('DD MMM, YYYY'))
  });
})

$(document).on('change', '#base_salaries_csv', (e) => {
  add_csv_file(e)
});

const add_csv_file = (e) => {
  const fileName = $(e.target).val().split("\\").pop();
  if (fileName.replace(/^.*\./, '') === 'csv') {
    $(e.target).siblings(".custom-file-label").addClass("selected").html(fileName);
    $('#import-base-salaries-btn').attr("disabled", false);
  } else {
    $('#import-base-salaries-btn').attr("disabled", true);
    $(e.target).siblings(".custom-file-label").addClass("selected").html(fileName);
    $(e.target).parent().append("<p id='error' style='color: red'>Please choose csv file.</p>");
    setTimeout(() => {
      $('#import-base-salaries-btn').attr("disabled", true);
      $('#error').remove();
    }, 5000);
    e.preventDefault();
  }
}

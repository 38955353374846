import { Controller } from "stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static values = {
    title: String,
    message: String,
    url: String,
    reqtype: String,
    timer: Boolean,
    timerduration: Number,
    moduletype: String,
    form: Boolean
  }

  connect() {}

  click = (e) => {
    e.preventDefault();

    let title = this.titleValue;
    let message = this.messageValue;
    let url = this.urlValue;
    let reqtype = this.reqtypeValue;
    let timer = this.timerValue;
    let timerduration = this.timerdurationValue;
    let moduletype = this.moduletypeValue;
    let form = this.formValue == true ? e.target.closest('form') : null
    let okButtonHtml = `<a class="btn btn-primary" data-bs-dismiss="modal" id="confirmModalConfirmButton">Proceed</a>`
    let timerSpanHtml = `<span id="confirmModalTimer">${timerduration}</span>`

    let html = `
      <div class="modal fade" id="confirmDialog" tabindex="-1" role="dialog" aria-labelledby="ariaConfirmModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="confirmModalTitle">${title}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">&times</button>
            </div>
            <div class="modal-body">
              ${message}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" id="confirmModalCancelButton" data-dismiss="modal">Cancel</button>
              ${ (timer == true) ? timerSpanHtml : okButtonHtml }
            </div>
          </div>
        </div>
      </div >
      `;

    let confirmationAlertModalDiv = $('.confirmation-alert-modal')[0]
    confirmationAlertModalDiv.innerHTML = html;

    let modal = document.querySelector('#confirmDialog');
    var confirmModal = new Modal(modal);
    confirmModal.show();

    $('#confirmDialog').on('hidden.bs.modal', (e) => {
      confirmationAlertModalDiv.innerHTML = ''
    })

    if (timer == true) {
      const modalInterval = setInterval(() => {
        let timerCount = $('#confirmModalTimer').text();
        $('#confirmModalTimer').text(timerCount - 1);
        if ($('#confirmModalTimer').text() == 0) {
          $('#confirmModalTimer').replaceWith(okButtonHtml);
          if(moduletype == 'hr_request') {
            sendAjaxRequestForRequestApproval(reqtype, url, true, true);
          } else {
            sendAjaxRequest();
          }
          clearInterval(modalInterval);
        }
      }, 1000);
    }

    const sendAjaxRequest = () => {
      $('#confirmModalConfirmButton').on('click', (e) => {
        $('#confirmModalConfirmButton').addClass( "disabled" );
        $('#confirmModalConfirmButton').text('Please wait ...')
        e.preventDefault();
        $.ajax({
          type: reqtype,
          url: url,
          data: form ? getFormDataAsObject(form) : {}
        })
      })
    }

    if (timer == false) {
      sendAjaxRequest();
    }

    const sendAjaxRequestForRequestApproval = (reqtype, url, approve_request, multiple_request) => {
      $('#confirmModalConfirmButton').on('click', (e) => {
        $('#confirmModalConfirmButton').addClass( "disabled" );
        $('#confirmModalConfirmButton').text('Please wait ...')
        e.preventDefault();
        let selectedRequestIds = $('.request-checkbox')
        const requestIds = []
        selectedRequestIds.each(index => {
          if ($(selectedRequestIds[index])[0].checked) {
            requestIds.push(parseInt($(selectedRequestIds[index]).data('request-id')))
          }
        })
        $.ajax({
          type: reqtype,
          url: url,
          data: { selected_request_id: requestIds, multiple_request: multiple_request, approve: approve_request },
          success(response) {
            $('#confirmDialog').find('form').trigger('reset');
            $('#confirmDialog').hide();
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
            if (response.approved_or_rejected_ids) {
              var success_message_for_approval_or_rejection = ''
              const methodName = approve_request ? 'approved' : 'rejected'
              if (response.failed_request_ids.length !== 0 ) {
                success_message_for_approval_or_rejection = `Selected requests are ${methodName} successfully
                                                              except request(s) with id(s): ${response.failed_request_ids}`
              } else {
                success_message_for_approval_or_rejection = `Selected requests are ${methodName} successfully`
              }
              $('.alert-container').removeClass('d-none');
              const header_success_message = `<p class='text-success  px-4 pt-4'> <span class='fas fa-check-circle fa-lg mx-2'>\
                                          </span><strong> ${success_message_for_approval_or_rejection} </strong>\
                                          <button class='close' data-dismiss='alert' type='button' id='close-success-alert'> \
                                          <span aria-hidden='true'>×</span> \
                                          <span class='sr-only'>Close</span> \
                                          </button> \
                                          </p>`;
              const success_message = `<div class='alert alert-success w-100'>\
                                      <h6> ${header_success_message} </h6>`
              "</div>";
              $('.alert-container').html(success_message);
              $('.alert-container').fadeTo(5000, 1000).slideUp(1000, function () {
                $('.alert-container').slideUp(1000);
              });
            }
            if (response.failed_request_ids.length !== 0 && response.approved_or_rejected_ids.length == 0) {
              const methodName = approve_request ? 'Approve' : 'Reject'
              let header_error_message = `<p class='text-danger px-4 pt-4'> \
                                    <strong> Failed to ${methodName} selected requests </strong>\
                                    <button class='close' type='button' id='close-errors-alert'> \
                                      <span aria-hidden='true'>×</span> \
                                      <span class='sr-only'>Close</span> \
                                    </button> \
                                  </p>`;
              const error_message = `<div class='alert alert-danger w-100'>\
              <h6> ${header_error_message} </h6>`
              "</div>";
              $('.alert-container').html(error_message);
              $('.alert-container').fadeTo(5000, 1000).slideUp(1000, function () {
                $('.alert-container').slideUp(1000);
              });
            }
            $('.header-checkbox').prop('checked', false)
            $('.approve-reject-buttons').addClass('d-none')
            $('html').scrollTop(0);
            refresh_request_table();
            $('body').css('padding-right', '0');
          }
        })
      })
    }

    const refresh_request_table = () =>{
      $('#hr-requests').DataTable().destroy()
      $('.hr-request-edit-form').empty();
      $('#hr-requests').DataTable({
        fixedHeader: true,
        pageLength: 25,
        processing: true,
        serverSide: true,
        ajax: {
          'type': 'get',
          'url': '/hr_requests',
          'dataType': 'json',
          'data': (data) => {
            data.page = parseInt($('#hr-requests').DataTable().page.info().page) + 1
            data.per_page = $('#hr-requests').DataTable().page.info().length
            data.search = data.search.value
            data.order = $('#hr-requests').DataTable().order()[0][0]
            data.direction = $('#hr-requests').DataTable().order()[0][1]
            data.status = $('#status-filter').val()
            data.category = $('#categories-filter').val()
          }
        },
        "columns" : [ { "width": "0.25%" }, { "width": "0.75%" }, { "width": "2%" }, { "width": "2.25%" },
                      { "width": "5%" }, { "width": "1%" }, { "width": "0.5%" }, { "width": "0.5%" }
                    ],
        order: [[1, 'desc']],
        "drawCallback": () => {
          $('body').css('overflow', 'scroll');
          $(".custom-date-input").datetimepicker({
            format: "DD MMM, YYYY",
            icons: {
              previous: "fas fa-chevron-left",
              next: "fas fa-chevron-right",
            },
          });
          disableHeaderCheckBox();
          $('.btn-disabled').prop('disabled', true);
        },
        deferRender: true,
        columnDefs: [
          {
            'targets': 3,
            'createdCell': (td, cellData, rowData, row, col) => {
              if(rowData[8].status == 'approved'){
                $(td).addClass('text-success')
              }
            }
          },
          {
            'targets': [0,1,2,3,4,5,6],
            'createdCell': (td, cellData, rowData, row, col) => {
              $(td).addClass('cursor-pointer')
              if(rowData[1]){
                $(td).closest('tr').attr({
                  'data-toggle': 'modal',
                  'data-target': `#show-request-${rowData[1]}`
                })
              }
            }
          },
          {
            'targets': 7,
            'createdCell': (td, cellData, rowData, row, col) => {
              $(td).addClass('disable-propagation')
              if(rowData[8].child_request){
                $(td).closest('tr').addClass(`child-request-${rowData[8].parent_id}`)
              }
            }
          },
          {
            'targets': [0,2,3,4,5,6,7],
            "bSortable": false
          }
        ]
      });
      debounceSearch('#hr-requests')
    }
    const disableHeaderCheckBox = () => {
      if ($('input.request-checkbox').length == 0) {
        $('.header-checkbox').prop('disabled', true)
      } else {
        $('.header-checkbox').prop('disabled', false)
      }
      $('.header-checkbox').prop('checked', false)
      $('.approve-reject-buttons').addClass('d-none')
    }

    const getFormDataAsObject = (form) =>{
      let formDataObj = {};

      for(let element of form.elements) {
        if(element.name) {
          formDataObj[element.name] = element.value;
        }
      }
      return formDataObj;
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  initialize_one_time_allowance_datepicker();
  filter_requests();
});

const render_requests_table = (status, category) => {
  $('#hr-requests').DataTable().destroy()
  $('.hr-request-edit-form').empty();
  $('#hr-requests').DataTable({
    fixedHeader: true,
    pageLength: 25,
    processing: true,
    serverSide: true,
    ajax: {
      'type': 'get',
      'url': '/hr_requests',
      'dataType': 'json',
      'data': (data) => {
        data.page = parseInt($('#hr-requests').DataTable().page.info().page) + 1
        data.per_page = $('#hr-requests').DataTable().page.info().length
        data.search = data.search.value
        data.order = $('#hr-requests').DataTable().order()[0][0]
        data.direction = $('#hr-requests').DataTable().order()[0][1]
        data.status = status
        data.category = category
      }
    },
    "columns" : [ { "width": "0.25%" }, { "width": "0.75%" }, { "width": "2%" }, { "width": "2.25%" },
                  { "width": "5%" }, { "width": "1%" }, { "width": "0.5%" }, { "width": "0.5%" }
                ],
    order: [[1, 'desc']],
    "drawCallback": () => {
      $('body').css('overflow', 'scroll');
      $(".custom-date-input").datetimepicker({
        format: "DD MMM, YYYY",
        icons: {
          previous: "fas fa-chevron-left",
          next: "fas fa-chevron-right",
        },
      });
      initialize_one_time_allowance_datepicker();
      disableHeaderCheckBox()
      $('.btn-disabled').prop('disabled', true);
    },
    deferRender: true,
    columnDefs: [
      {
        'targets': 3,
        'createdCell': (td, cellData, rowData, row, col) => {
          if(rowData[8].status == 'approved'){
            $(td).addClass('text-success')
          }
        }
      },
      {
        'targets': [0,1,2,3,4,5,6],
        'createdCell': (td, cellData, rowData, row, col) => {
          $(td).addClass('cursor-pointer')
          if(rowData[1]){
            $(td).closest('tr').attr({
              'data-toggle': 'modal',
              'data-target': `#show-request-${rowData[1]}`
            })
          }
        }
      },
      {
        'targets': 7,
        'createdCell': (td, cellData, rowData, row, col) => {
          $(td).addClass('disable-propagation')
          if(rowData[8].child_request){
            $(td).closest('tr').addClass(`child-request-${rowData[8].parent_id}`)
          }
          if(rowData[8]) {
            appendRequestEditForm(rowData[8])
          }
        }
      },
      {
        'targets': [0,2,3,4,5,6,7],
        "bSortable": false
      }
    ]
  });
  debounceSearch('#hr-requests')
}

const disableHeaderCheckBox = () => {
  if ($('input.request-checkbox').length == 0) {
    $('.header-checkbox').prop('disabled', true)
  } else {
    $('.header-checkbox').prop('disabled', false)
  }
  $('.header-checkbox').prop('checked', false)
  $('.approve-reject-buttons').addClass('d-none')
}

const appendRequestEditForm = (rowData) => {
  let updateModalDiv = document.createElement("div");
  updateModalDiv.classList.add('request-update-modal')
  updateModalDiv.classList.add('modal')
  updateModalDiv.classList.add('fade')
  updateModalDiv.classList.add('disable-propagation')
  updateModalDiv.id = `update-request-${ rowData.request_id }`
  updateModalDiv.role = 'dialog'
  updateModalDiv.innerHTML = rowData.request_edit_form
  $('.hr-request-edit-form')[0].appendChild(updateModalDiv);
}

$(document).on('click', '#approve-request-btn', (e) => {
  let request_id = $(e.target).data('request-id');
  sendAjaxRequestForApproveReject('Patch', `/hr_requests/approve_or_reject_requests`, request_id, true, false, '', $('.remarks-modal'));
})

$(document).on('click', '#reject-request-btn', (e) => {
  let request_id = $(e.target).data('hr-request-id');
  let remarks = $(`#${request_id}-rejection-remarks`).val();
  sendAjaxRequestForApproveReject('Patch', `/hr_requests/approve_or_reject_requests`, request_id, false, false, remarks, $('.remarks-modal'));
})

$(document).on('click', '#reject-multiple-request-btn', (e) => {
  let remarks = $('#remarks-for-bulk-rejection').val();
  let selectedRequestIds = $('.request-checkbox')
  const requestIds = []
  selectedRequestIds.each(index => {
    if ($(selectedRequestIds[index])[0].checked) {
      requestIds.push(parseInt($(selectedRequestIds[index]).data('request-id')))
    }
  })
  sendAjaxRequestForApproveReject('Patch', `/hr_requests/approve_or_reject_requests`, requestIds, false, true, remarks, $('#rejection_remarks_modal'));
})

$(document).on('ajax:error ajax:success', '#request-edit-form', (e) => {
  const { errors, success_message, warning_message } = e.detail[0];
  if (errors) {
    append_errors(errors, 'Cannot Update due to following issues:')
    removeErrorAndWarningMessage('.request-update-modal', $('.ajax_errors'))
  }
  if (warning_message) {
    const show_warning_message = `<div class='alert alert-warning w-100'><h6 class='pt-2'>${warning_message}.</h6>`
                                  "</div>";
    warning_message_div = $('.warning_message')
    warning_message_div.removeClass('d-none')
    warning_message_div.html(show_warning_message);
    $('.auto_reject').val(true)
    removeErrorAndWarningMessage('.request-update-modal', warning_message_div)
  }
  if (success_message) {
    $('.request-update-modal').find('form').trigger('reset');
    $('.request-update-modal').modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    displaySuccessMessageInAlertContainer(success_message)
    error_div = $('.ajax_errors')
    if(!error_div.hasClass('d-none')) {
      error_div.addClass('d-none')
    }
    $('html').scrollTop(0);
    filter_requests();
  }
})

const sendAjaxRequestForApproveReject = (reqtype, url, request_id, approve_request, multiple_request, remarks, remarks_modal) => {
  $.ajax({
    type: reqtype,
    url: url,
    data: { selected_request_id: request_id, multiple_request: multiple_request, approve: approve_request, remarks: remarks },
    success(response) {
      remarks_modal.find('form').trigger('reset');
      remarks_modal.hide();
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
      if ($('.header-checkbox').prop('checked')) {
        $('.header-checkbox').prop('checked', false)
        $('.approve-reject-buttons').addClass('d-none')
      }
      if (response.error_message.length !== 0) {
        displayErrorMessageInAlertContainer(response.error_message);
      }
      if ( response.success_message.length !== 0) {
        displaySuccessMessageInAlertContainer(response.success_message)
      }
      if (multiple_request && response.approved_or_rejected_ids) {
        var success_message_for_approval_or_rejection = ''
        const methodName = approve_request ? 'Approved' : 'Rejected'
        if (response.failed_request_ids.length !== 0 ) {
          success_message_for_approval_or_rejection = `Selected requests are ${methodName} successfully
                                                        except request(s) with id(s): ${response.failed_request_ids}`
        } else {
          success_message_for_approval_or_rejection = `Selected requests are ${methodName} successfully`
        }
        $('.alert-container').removeClass('d-none');
        const header_success_message = `<p class='text-success  px-4 pt-4'> <span class='fas fa-check-circle fa-lg mx-2'>\
                                    </span><strong> ${success_message_for_approval_or_rejection} </strong>\
                                    <button class='close' data-dismiss='alert' type='button' id='close-success-alert'> \
                                    <span aria-hidden='true'>×</span> \
                                    <span class='sr-only'>Close</span> \
                                    </button> \
                                    </p>`;
        const success_message = `<div class='alert alert-success w-100'>\
                                <h6> ${header_success_message} </h6>`
        "</div>";
        $('.alert-container').html(success_message);
        $('.alert-container').fadeTo(5000, 1000).slideUp(1000, function () {
          $('.alert-container').slideUp(1000);
        });
      }
      if (multiple_request && response.failed_request_ids.length !== 0 && response.approved_or_rejected_ids.length == 0) {
        const methodName = approve_request ? 'approve' : 'reject'
        let header_error_message = `<p class='text-danger px-4 pt-4'> \
                              <strong> Failed to ${methodName} selected requests </strong>\
                              <button class='close' type='button' id='close-errors-alert'> \
                                <span aria-hidden='true'>×</span> \
                                <span class='sr-only'>Close</span> \
                              </button> \
                            </p>`;
        const error_message = `<div class='alert alert-danger w-100'>\
        <h6> ${header_error_message} </h6>`
        "</div>";
        $('.alert-container').html(error_message);
        $('.alert-container').fadeTo(5000, 1000).slideUp(1000, function () {
          $('.alert-container').slideUp(1000);
        });
      }
      $('.approve-reject-buttons').addClass('d-none')
      $('html').scrollTop(0);
      filter_requests();
    }
  })
}

const displayErrorMessageInAlertContainer = (message) => {
  let header_error_message = `<p class='text-danger px-4 pt-4'> \
                              <strong> ${message} </strong>\
                              <button class='close' type='button' id='close-errors-alert'> \
                                <span aria-hidden='true'>×</span> \
                                <span class='sr-only'>Close</span> \
                              </button> \
                            </p>`;
  const error_message = `<div class='alert alert-danger w-100'>\
  <h6> ${header_error_message} </h6>`
  "</div>";
  $('.alert-container').html(error_message);
  $('.alert-container').fadeTo(5000, 1000).slideUp(1000, function () {
    $('.alert-container').slideUp(1000);
  });
}

const displaySuccessMessageInAlertContainer = (message) => {
  $('.alert-container').removeClass('d-none');
  const header_success_message = `<p class='text-success  px-4 pt-4'> <span class='fas fa-check-circle fa-lg mx-2'>\
                              </span><strong> ${message} </strong>\
                              <button class='close' data-dismiss='alert' type='button' id='close-success-alert'> \
                              <span aria-hidden='true'>×</span> \
                              <span class='sr-only'>Close</span> \
                              </button> \
                              </p>`;
  const success_message = `<div class='alert alert-success w-100'>\
                          <h6> ${header_success_message} </h6>`
  "</div>";
  $('.alert-container').html(success_message);
  $('.alert-container').fadeTo(5000, 1000).slideUp(1000, function () {
    $('.alert-container').slideUp(1000);
  });
}

const append_errors = (errors, message) => {
  const header_message = `<p class='text-danger px-4 pt-2'> <span class='fas fa-times-circle fa-lg'>\
                            </span> <strong>${message}</strong>\
                            <button class='close' type='button' id='close-errors-alert'> \
                              <span aria-hidden='true'>×</span> \
                              <span class='sr-only'>Close</span> \
                            </button> \
                          </p>`;
  error_div = $('.ajax_errors')
  error_div.removeClass('d-none')
  error_div.html(header_message);
  for (let error of Array.from(errors)) {
    const error_message = `<div class='text-danger px-5' > \
                              <ul> <li> ${error} </li> \
                              </ul>\
                          </div>`;
    error_div.append(error_message);
  }
}


$(document).on('change', '.header-checkbox', (e) => {
  if ($(e.target).prop('checked')) {
    $('.request-checkbox').prop('checked', true)
    $('.approve-reject-buttons').removeClass('d-none')
  } else {
    $('.request-checkbox').prop('checked', false)
    $('.approve-reject-buttons').addClass('d-none')

  }
})

const removeErrorAndWarningMessage = (modal, message_div) => {
  $(document).on('hidden.bs.modal', `${modal}`, () => {
    message_div.addClass('d-none')
    $('.auto_reject').val(false)
  })
}


$(document).on('change', '.request-checkbox', (e) => {
  if ($(e.target).prop('checked')) {
    $('.approve-reject-buttons').removeClass('d-none')
  } else if($('input.request-checkbox').not(":checked").length == $('input.request-checkbox').length) {
    $('.approve-reject-buttons').addClass('d-none')
  }
})

$(document).on('click', '.arrow-btn', (e) => {
  const childDiv = $(`.child-request-${e.target.id.substr(15)}`)
  const parentArrowButton = $(e.target)
  if (childDiv.hasClass('d-none')) {
    childDiv.removeClass('d-none')
    parentArrowButton.removeClass('fa-arrow-circle-up')
    parentArrowButton.addClass('fa-arrow-circle-down')
  } else  {
    childDiv.addClass('d-none')
    parentArrowButton.removeClass('fa-arrow-circle-down')
    parentArrowButton.addClass('fa-arrow-circle-up')
  }
})

$(document).on('change.select2', '#status-filter, #categories-filter', () => {
  setTimeout(() => { filter_requests(); }, 500);
  $('.approve-reject-buttons').addClass('d-none')
  $('.header-checkbox').prop('checked', false)
 });

const filter_requests = () => {
  let status = $('#status-filter').val();
  let category = $('#categories-filter').val();
  render_requests_table(status, category)
}

const initialize_one_time_allowance_datepicker = () => {
  $('.one-time-allowance-datepicker').datetimepicker({
    format: "MMM, YYYY",
      viewMode: "months",
      icons: {
        previous: "fas fa-chevron-left",
        next: "fas fa-chevron-right",
      }
  })
}

const moment = require("moment");
const open_allowance_path = '/open_allowances/';
const designation_allowance_path = '/designation_allowances/';
const group_allowance_path = '/group_allowances/';
var selected_employee_list = []

document.addEventListener('DOMContentLoaded', () => {
  initialize_plugins();
  select2_avatar_options();
  $('.dropdown-toggle').dropdown();
  append_hidden_input($('.select-employees'));
  append_hidden_input($('.select_designation_employees'));
  append_hidden_input($('.select_designations_group_employees'));
  load_month_year_picker()
  $('.select-designation, .select-group').select2()
  $('#allowance-month').select2();
  $('#allowance_month_drop').datetimepicker({
    format: 'MMM YYYY',
    viewMode: 'months',
    icons: {
      previous: "fas fa-chevron-left",
      next: "fas fa-chevron-right",
    }
  })

  $('#allowance-table').DataTable({
    fixedHeader: true,
    pageLength: 100,
    processing: true,
    serverSide: true,
    ajax: {
      'type': 'get',
      'url': '/allowances',
      'dataType': 'json',
      'data': (data) => {
        data.page = parseInt($('#allowance-table').DataTable().page.info().page) + 1
        data.per_page = $('#allowance-table').DataTable().page.info().length
        data.search = data.search.value
        data.order = $('#allowance-table').DataTable().order()[0][0]
        data.direction = $('#allowance-table').DataTable().order()[0][1]
      }
    },
    "drawCallback": () => {
      $('.yes-no-toggle').bootstrapToggle({
        on: 'Yes',
        off: 'No',
        width: '100%',
        size: 'small',
        height: 'match-parent',
        style: 'rounded'
      })
    },
    deferRender: true,
    columnDefs: [
      {
        'targets': 0,
        'createdCell': (td, cellData, rowData, row, col) => {
          $(td).addClass('col-sm-1')
        }
      },
      {
        'targets': 1,
        'createdCell': (td, cellData, rowData, row, col) => {
          $(td).addClass('col-sm-2 cursor-pointer')
          $(td).attr('onClick', `window.location='/allowances/${rowData[0]}'`)
        }
      },
      {
        'targets': 10,
        "bSortable": false,
        'createdCell': (td, cellData, rowData, row, col) => {
          $(td).addClass('col-sm-1 text-center')
        }
      }
    ]
  });
  if ( window.location.pathname.split('/')[1] == 'allowances' && window.location.pathname.split('/')[2]){
    render_employee_select_box('.select-employees', window.location.pathname.split('/')[2])
  }
  render_active_allowance_tab()
});

$('.yes-no-toggle').bootstrapToggle({
  on: 'Yes',
  off: 'No',
  width: '100%',
  size: 'small',
  height: 'match-parent',
  style: 'rounded'
})

$(document).on('click', '.edit_allowance', (e) => {
  let data_target = e.currentTarget.getAttribute('data-target');
  let allowance_id = data_target.split('-').at(-1);
  let taxable_toggle_div = $(`#taxable_toggle_${allowance_id}`);
  let reimbursable_toggle_div = $(`#reimubrsable_toggle_${allowance_id}`);
  let deduction_toggle = $(`#deduction_toggle_${allowance_id}`).find('.yes-no-toggle');

  $(`#recurring-toggle-${allowance_id}`).hide();
  $('.one-time-as-recurring-toggle').hide();
  if(deduction_toggle.prop('checked')) {
    $(`#delayed_toggle_${allowance_id}`).hide();
    $(`#reimubrsable_toggle_${allowance_id}`).hide();
  }
  $(data_target).find('input.recurring-or-one-time-toggle:checkbox').prop('checked') ? $('.perk_deductible_toggles').show() : $('.perk_deductible_toggles').hide()
  hide_custom_unit_fields($(`#deduction-toggle-${allowance_id}`));
  taxable_toggle_div.find('.yes-no-toggle').prop('checked') ? reimbursable_toggle_div.removeClass('d-none') : reimbursable_toggle_div.addClass('d-none')
})

$(document).on('click', '#add_new_allowance_btn', (e) => {
  $('#add_new_allowance').modal('show');
  $('.recurring_toggle').show();
  $('.perk_deductible_toggles').show();
  $('.custom-unit-button').removeClass('d-none');
  $('.one-time-as-recurring-toggle').hide();
})

$(document).on('click', '.remove-allowance-row', (e) => {
  $(e.target).closest('.allowance-details-form').remove();
  let dropdown
  if ($(e.target).closest("form").find('#allowance_of_type').val() == "OpenAllowance"){
    dropdown = $('.select-employees')
  } else {
    dropdown = $('.select-designation')
  }
  append_removed_option_in_select_elements(e, '.allowance-details-form', dropdown);
  e.preventDefault();
});

$(document).on('click', '.remove-designation-row', (e) => {
  $(e.target).closest('.designation-row').remove();
  let dropdown = $('.select-designation')
  append_removed_option_in_select_elements(e, '.designation-row', dropdown);
  e.preventDefault();
});

$(document).on('click', '.remove-employee-list-row-for-designation', (e) => {
  $(e.target).closest('.employee-list-form-for-designation').remove();
  append_removed_option_in_select_elements(e, '.employee-list-form-for-designation', $('.select_designation_employees'));
  e.preventDefault();
});

$(document).on('click', '.remove-employee-list-row-for-designations_group', (e) => {
  $(e.target).closest('.employee-list-form-for-designations_group').remove();
  append_removed_option_in_select_elements(e, '.employee-list-form-for-designations_group', $('.select_designations_group_employees'));
  e.preventDefault();
});

$(document).on('click', '#add-designation-row', (e) => {
  e.preventDefault();
  let group_id = $(e.currentTarget).data('group-id')
  let last_row_id, url, dropdown
  last_row_id = $('.select-designation:last').attr('id');
  url = '/groups/add_new_row'
  dropdown = $('.select-designation')
  $.ajax({
    type: 'GET',
    url: url,
    data: { row: parseInt(last_row_id?.match(/\d+/)?.at(0) || -1) + 1,
            id: group_id },
    success(response) {
      let new_row_id = $(response).find('select')[0].id
      $(e.target).parent().before(response);
      append_hidden_input($(`#${new_row_id}`));
      remove_selected_options_from_new_row(new_row_id, dropdown)
      initialize_plugins();
      load_month_year_picker();
      $(".custom-date-input").datetimepicker({
        format: "DD MMM, YYYY",
        icons: {
          previous: "fas fa-chevron-left",
          next: "fas fa-chevron-right",
        },
      });
      $('.select-designation').select2();
    }
  });
});

$(document).on('click', '#add-employee-allowance-row', (e) => {
  e.preventDefault();
  let last_row_id, url, dropdown
  if ($(e.target).closest("form").find('#allowance_of_type').val() == "OpenAllowance"){
    last_row_id = $('.select-employees:last').attr('id');
    url = `${open_allowance_path}add_new_row`
    dropdown = $('.select-employees')
  } else {
    last_row_id = $('.select-designation:last').attr('id');
    url = `${designation_allowance_path}add_new_row`
    dropdown = $('.select-designation')
  }
  $.ajax({
    async: false,
    type: 'GET',
    url: url,
    data: { row: parseInt(last_row_id?.match(/\d+/)?.at(0) || -1) + 1, allowance_id: $(e.target).data('allowance-id') },
    success(response) {
      let new_row_id = $(response).find('select')[0].id
      $(e.target).parent().before(response);
      append_hidden_input($(`#${new_row_id}`));
      remove_selected_options_from_new_row(new_row_id, dropdown)
      render_employee_select_box(`#${new_row_id}` , $(e.target).data('allowance-id'), true, new_row_id)
      initialize_plugins();
      load_month_year_picker();
      $(".custom-date-input").datetimepicker({
        format: "DD MMM, YYYY",
        icons: {
          previous: "fas fa-chevron-left",
          next: "fas fa-chevron-right",
        },
      });
      $('.select-designation').select2();
    }
  });
});

$(document).on('change', '.select-current-month', (e) => {
  let target_toggle = $(e.target);
  let form = target_toggle.closest('.allowance-details-form')
  let date_selectors = form.find('.dates-for-employee-allowance').find('input');
  let continuation_toggle = form.find('.continuation-toggle').prop('checked')
  $(date_selectors[0]).attr('disabled', target_toggle.prop('checked'))
  if (continuation_toggle === false) { $(date_selectors[1]).attr('disabled', target_toggle.prop('checked')) }
});

$(document).on('change', '.continuation-toggle', (e) => {
  let target_toggle = $(e.target);
  let form = target_toggle.closest('.allowance-details-form')
  let select_current_month_toggle = form.find('.select-current-month').prop('checked')
  let allowance_name
  if (form.attr('id') == 'add-group-allowance' || form.attr('id') == 'add-rank-allowance') {
    allowance_name = 'group_allowance'
  } else if (form.attr('id') == 'add-designation-allowance') {
    allowance_name = 'designation_allowance'
  } else if (form.attr('id') == 'add-employee-for-open-allowance') {
    allowance_name = 'open_allowance'
  }
  let row = form.data('row-num');
  if (form.attr('id') == 'new_designation_allowance'){
    allowance_name = 'designation_allowance'
  } else if (form.attr('id') == 'new_group_allowance'){
    allowance_name = 'group_allowance'
  } else if (form.attr('id') == 'new_open_allowance'){
    allowance_name = 'open_allowance'
  }
  let end_date_selector_name = row === undefined ? `input[name='${allowance_name}[end_date]']` : `input[name='assigned_allowance[${row}][end_date]']`;
  let start_date_selector_name = row === undefined ? `input[name='${allowance_name}[start_date]']` : `input[name='assigned_allowance[${row}][start_date]']`;
  let end_date_selector = form.find('.dates-for-employee-allowance').find(end_date_selector_name);
  let start_date_selector = form.find('.dates-for-employee-allowance').find(start_date_selector_name);
  target_toggle.prop('checked') ? end_date_selector.val('') : end_date_selector.val(moment(start_date_selector.val()).endOf('month').format('DD MMM, YYYY'));
  if (select_current_month_toggle) { return }

  end_date_selector.attr('disabled', target_toggle.prop('checked'));
});

selected_designation = () => {
  return $('.select-designation').find('option:selected')[0].label.split('(')[0].trim()
}

selected_group = (allowance_type = 'Group') => {
  return (allowance_type === 'Group' ? $('.group-select') : $('.rank-select')).find('option:selected')[0].label
}

selected_levels = (e) => {
  var selectedValues = [];
  $(e.target).find(':selected').each(function() {
    selectedValues.push($(this).val());
  });
  return selectedValues;
}

$(document).on('show.bs.modal', '#add_multiple_designations, #create-designations-group', (e) => {
  $('.select-designation').select2();
  append_hidden_input($('.select-designation'));
  $(e.target).on('change', '.select-designation', (e) => {
    remove_option_and_update_hidden_field(e, '.select-designation')
  })
})

$(document).on('click', '#add-nested-employee-list-row-for-designation', (e) => {
  e.preventDefault();
  const designation = selected_designation()
  append_employees_list_row_for_designation(true, '#add-nested-employee-list-row-for-designation', designation)
});

$(document).on('click', '#add-nested-employee-list-row-for-designations_group', (e) => {
  e.preventDefault();
  const group = selected_group()
  append_employees_list_row_for_designations_group(true, '#add-nested-employee-list-row-for-designations_group', group)
});

$(document).on('click', '#add-employee-lists-row', (e) => {
  e.preventDefault();
  designation = $('#designation-title').text().split(/([0-9]+)/)[0].split(/\n/)[0]
  append_employees_list_row_for_designation(false, '#add-employee-lists-row', designation)
  select2_avatar_options()
});

$(document).on('click', '#add-employee-lists-row-for-group-allowance', (e) => {
  e.preventDefault();
  group = $("#designation-title").find(".allowance-display-name").text();
  level_ids = $(e.target).data("level-ids");
  append_employees_list_row_for_designations_group(
    false,
    "#add-employee-lists-row-for-group-allowance",
    group,
    level_ids
  );
  select2_avatar_options()
});

$(document).on('change', '#one_time_employees_csv ', (e) => {
  const fileName = $(e.target).val().split("\\").pop();
  if (fileName.replace(/^.*\./, '') === 'csv') {
    $(e.target).siblings(".custom-file-label").addClass("selected").html(fileName);
    $('#import-one-time-employees-btn').attr("disabled", false);
  } else {
    $('#import-one-time-employees-btn').attr("disabled", true);
    $(e.target).siblings(".custom-file-label").addClass("selected").html(fileName);
    $(e.target).parent().append("<p id='error' style='color: red'>Please choose csv file.</p>");
    setTimeout(() => {
      $('#import-one-time-employees-btn').attr("disabled", true);
      $('#error').remove();
    }, 5000);
    e.preventDefault();
  }
});

$(document).on('change', '#open_allowance_employees_csv ', (e) => {
  const fileName = $(e.target).val().split("\\").pop();
  if (fileName.replace(/^.*\./, '') === 'csv') {
    $(e.target).siblings(".custom-file-label").addClass("selected").html(fileName);
    $('#import-open-allowance-employees-btn').attr("disabled", false);
  } else {
    $('#import-open-allowance-employees-btn').attr("disabled", true);
    $(e.target).siblings(".custom-file-label").addClass("selected").html(fileName);
    $(e.target).parent().append("<p id='error' style='color: red'>Please choose csv file.</p>");
    setTimeout(() => {
      $('#import-open-allowance-employees-btn').attr("disabled", true);
      $('#error').remove();
    }, 5000);
    e.preventDefault();
  }
});

$(document).on('change', '.select-designation', (e) => {
  if ($('.employee-list-form-for-designation').length) {
    append_designation_employees();
    select2_avatar_options();
  }
});

$(document).on('change', '.select-group', (e) => {
  allowance_type = e.currentTarget.classList.contains('rank-select') ? 'Rank' : 'Group'
  if ($('.employee-list-form-for-designations_group').length) {
    append_grouped_designation_employees(allowance_type);
    select2_avatar_options();
  }
});

$(document).on('change', '.group-select', (e) => {
  append_designation_level_options($('#group_allowance_level_ids'), e.currentTarget.value)
})

$(document).on('change', '.rank-select', (e) => {
  append_designation_level_options($('#rank_allowance_level_ids'), e.currentTarget.value)
})

$(document).on('change', '.level-ids', (e) => {
  allowance_type = e.currentTarget.getAttribute("data-allowance-type");
  append_level_employees(e, allowance_type || "Group");
  select2_avatar_options();
})

const append_designation_level_options = (dropdown, selected_value) => {
  $.ajax({
    type: 'GET',
    url: `/groups/${selected_value}`,
    success(response) {
      dropdown.prop('disabled', selected_value == '')
      dropdown.empty();
      $.each(response.designation_levels, function(_index, level) {
        let option = $("<option></option>").text(`Level-${level}`).val(level);
        dropdown.append(option);
      });
    }
  });
}

const append_grouped_designation_employees = (allowance_type = 'Group') => {
  const group = selected_group(allowance_type)
  $.ajax({
    type: 'GET',
    url: '/employee_lists/new',
    data: {
      employee_list_for: 'designations_group',
      group: group
    },
    success(response) {
      allowance_type === 'Group' ? $('#list-nested-form-for-group').html(response) : $('#list-nested-form-for-rank').html(response)
      apply_custom_date_and_select2()
      select2_avatar_options()
      append_hidden_input($('.select_designations_group_employees'));
    }
  });
}

$(document).on('change', "input[name='designation_allowance[add_employee_lists]']", (e) => {
  if (e.currentTarget.value == 'true') {
    append_designation_employees()
  } else {
    $('#list-nested-form-for-designation').html('')
  }
});

$(document).on('change', "input[name='group_allowance[add_employee_lists]']", (e) => {
  allowance_type = e.currentTarget.parentElement.getAttribute('allowance_type');
  if (e.currentTarget.value == 'true') {
    append_grouped_designation_employees(allowance_type);
  } else {
    allowance_type === 'Group' ? $('#list-nested-form-for-group').html('') : $('#list-nested-form-for-rank').html('')
  }
});

$(document).on('change', '.recurring-or-one-time-toggle', (e) => {
  if($(e.target).prop('checked')) {
    $('.perk_deductible_toggles').show();
    let yes_no_toggle = $('.one-time-as-recurring-toggle').find('.yes-no-toggle')
    if(yes_no_toggle.prop('checked')) { yes_no_toggle.bootstrapToggle('toggle'); }
    $('.one-time-as-recurring-toggle').hide();
  } else {
    $('.perk_deductible_toggles').hide();
    $('.one-time-as-recurring-toggle').show();
  }
})

$(document).on('show.bs.modal', '#add_multiple_employees', (e) => {
  append_hidden_input($('.select-employees'));
  $(e.target).on('change', '.select-employees', (e) => {
    selected_employee_list.push($(`#${e.currentTarget.id}`).val())
  })
})

$(document).on('show.bs.modal', '#add_employee_list', (e) => {
  append_hidden_input($('.select-employees'));
  $(e.target).on('change', '.select-employees', (e) => {
    remove_option_and_update_hidden_field(e, '.select-employees')
  })
})

$(document).on('show.bs.modal', '#add_designation_allowance', (e) => {
  $('.select-designation').select2();
  select2_avatar_options()
  $(e.target).on('change', '.select_designation_employees', (e) => {
    remove_option_and_update_hidden_field(e, '.select_designation_employees')
  })
})

$(document).on('show.bs.modal', '#add_group_allowance', (e) => {
  $('.select-group').select2();
  select2_avatar_options();
  $(e.target).on('change', '.select_designations_group_employees', (e) => {
    remove_option_and_update_hidden_field(e, '.select_designations_group_employees')
  })
});

$(document).on('show.bs.modal', '#add_rank_allowance', (e) => {
  $('.select-group').select2();
  select2_avatar_options();
  $(e.target).on('change', '.select_designations_group_employees', (e) => {
    remove_option_and_update_hidden_field(e, '.select_designations_group_employees')
  })
});

$(document).on('click', '.show_allowance_history_button', (e) => {
  let allowance_id = e.currentTarget.getAttribute('allowance_id');
  $.ajax({
    type: 'GET',
    url: '/change_history',
    dataType: 'script',
    data: { model_name: 'Allowance', ids: [allowance_id] }
  })
})

$(document).on('click', '.delete_allowance_button', (e) => {
  let allowance_id = e.currentTarget.getAttribute('allowance_id');
  if(confirm('Are you sure you want to delete this Allowance?') == true) {
    $.ajax({
      type: 'DELETE',
      url: `/allowances/${allowance_id}`,
      dataType: 'JSON',
      success(response) {
        location.reload();
      }
    });
  };
})

$(document).on('click', '#applicable_till_btn', (e) => {
  $('#applicable-till-form').modal('show');
})

$(document).on('ajax:error ajax:success', '#add-employee-for-open-allowance', (e) => {
  append_messages_inside_modal(e, $('#add_open_allowance'), 'open_allowances', $('.select-employees'));
});

$(document).on('ajax:error ajax:success', '#add-designation-allowance', (e) => {
  append_messages_inside_modal(e, $('#add_designation_allowance'), 'designation_allowances', $('.select-designation'));
});

$(document).on('ajax:error ajax:success', '#import-designation-allowances-from-csv', (e) => {
  append_messages_inside_modal(e, $('#add_designations_from_csv'), 'designation_allowances', $('.custom-file-input'));
});

$(document).on('ajax:error ajax:success', '#add-group-allowance', (e) => {
  append_messages_inside_modal(e, $('#add_group_allowance'), 'group_allowances', $('.select-group'));
});

$(document).on('ajax:error ajax:success', '#add-rank-allowance', (e) => {
  append_messages_inside_modal(e, $('#add_rank_allowance'), 'group_allowances', $('.rank-select'));
});

$(document).on('ajax:error ajax:success', '.revision-form', (e) => {
  append_messages_inside_modal(e, $('#add_allowance_revision'), 'revision', $('.select-employees'));
});

$(document).on('ajax:error ajax:success', '#add-allowance-end-date-form', (e) => {
  append_messages_inside_modal(e, $('#allowance-end-date-form'), 'end-date-form', $('.select-employees'));
});

$(document).on('ajax:error ajax:success', '#add-multiple-entities-in-allowance', (e) => {
  if (e.detail[0].open_allowance){
    append_messages_inside_modal_for_multiple_employees(e, $('#add_multiple_employees'), 'open_allowances');
  } else {
    append_messages_inside_modal_for_multiple_employees(e, $('#add_multiple_designations'), 'designation_allowances');
  }
});

$(document).on('ajax:error ajax:success', '#add-employee-list-from', (e) => {
  append_messages_inside_modal_for_multiple_employees(e, $('#add_employee_list'), 'employee_list');
});

$(document).on('hidden.bs.modal', '#allowance-end-date-form', () => {
  if(!$('#ajax_errors').hasClass('d-none')) {
    error_message_div.addClass('d-none')
  }
  if(!$('#warning_message').hasClass('d-none')) {
    $('#warning_message').addClass('d-none')
  }
  $('#merge_request').val(false)
})

$(document).on('change', '.deduction-toggle', (e) => {
  hide_custom_unit_fields(e.target);
  hide_or_show_reimbursable_and_taxable_toggles(e.target, '.deduction_toggle_div');
  hide_or_show_delayed_allowance_toggle(e.target, '.deduction_toggle_div');
})

$(document).on('change', '.delayed_toggle', (e) => {
  hide_or_show_reimbursable_and_taxable_toggles(e.target, '.delayed_toggle_div');
});

$(document).on('change', '.taxable_toggle', (e) => {
  hide_or_show_reimbursable_and_taxable_toggles(e.target, '.taxable_toggle_div');
});

$(document).on('click', '.archive_allowance_button', (e) => {
  document.querySelectorAll('.datepicker').forEach(e => {
    $(e).datetimepicker( {
      format: "DD MMM YYYY",
      useCurrent: false,
      minDate: $(e).data('min-date'),
      icons: {
        previous: "fas fa-chevron-left",
        next: "fas fa-chevron-right",
      }
    });
  })
});

$(document).on('click', '#close-errors-alert', (e) => {
  $('.alert-danger').addClass('d-none');
});

$(document).on('change.select2', '#allowance-category', () => { render_active_allowance_tab() });

$(document).on('change', '#designation_allowances_csv', (e) => {
  const fileName = $(e.target).val().split("\\").pop();
  if (fileName.replace(/^.*\./, '') === 'csv') {
    $(e.target).siblings(".custom-file-label").addClass("selected").html(fileName);
    $('#import-designation-allowances-btn').attr("disabled", false);
  } else {
    $('#import-designation-allowances-btn').attr("disabled", true);
    $(e.target).siblings(".custom-file-label").addClass("selected").html(fileName);
    $(e.target).parent().append("<p id='error' style='color: red'>Please choose csv file.</p>");
    setTimeout(() => {
      $('#import-designation-allowances-btn').attr("disabled", true);
      $('#error').remove();
    }, 5000);
    e.preventDefault();
  }
});

$(document).on('change', '.allowance_unit_type', (e) => {
  let form = $(e.currentTarget).closest("form")
  let custom_unit_dropdown_div = form.find('.custom-unit-dropdown-div')
  let custom_unit_dropdown = form.find('.custom_unit_dropdown')
  handle_custom_unit_dropdown(e.currentTarget.value, custom_unit_dropdown_div, custom_unit_dropdown)
});

$(document).on('shown.bs.modal', '.update-allowance-modal', (e) => {
  let amount_setting = $(e.currentTarget).find("input:radio.allowance_unit_type:checked")[0]
  if(amount_setting?.value) {
    let form = $(e.currentTarget).find("form")
    handle_custom_unit_dropdown(amount_setting.value, form.find('.custom-unit-dropdown-div'), form.find('.custom_unit_dropdown'))
  }
});

const initialize_plugins = () => {
  $('.current-month-toggle').bootstrapToggle({
    on: 'Current Salary',
    off: 'Custom Select',
    width: '100%',
    height: 'match-parent'
  });
  $('.yes-no-toggle').bootstrapToggle({
    on: 'Yes',
    off: 'No',
    width: '100%',
    size: 'small',
    height: 'match-parent',
    style: 'rounded'
  })
  $('.recurring-or-one-time-toggle').bootstrapToggle({
    on: 'Recurring',
    off: 'One Time',
    width: '100%',
    size: 'small',
    height: 'match-parent',
    style: 'rounded'
  })
  $('.approved-or-rejected-toggle').bootstrapToggle({
    on: 'Approve',
    off: 'Reject',
    width: '100%',
    size: 'small',
    height: 'match-parent',
    style: 'rounded',
    onstyle: 'success',
    offstyle: 'danger'
  })
  $('.continued-or-end-date-toggle').bootstrapToggle({
    on: 'Continued',
    off: 'Ending With',
    width: '100%',
    size: 'small',
    height: 'match-parent',
    style: 'rounded'
  })
  $('.salary-generation-toggle').bootstrapToggle({
    on: 'Unapproved Salaries',
    off: 'All Salaries',
    width: '100%',
    size: 'small',
    height: 'match-parent',
    style: 'rounded'
  })
  $('.fetch-data-toggle').bootstrapToggle({
    on: 'Fetch Latest Data',
    off: 'Process Local Data only',
    width: '100%',
    size: 'small',
    height: 'match-parent',
    style: 'rounded'
  })
  $('.filter-toggle').bootstrapToggle({
    on: 'Black List',
    off: 'White List',
    width: '100%',
    size: 'small',
    height: 'match-parent',
    style: 'rounded'
  })
}

$(document).on('change.select2', '#select-designation, #select-allowance-type, #select-allowance-status', () => { filter_allowances() });



const hide_custom_unit_fields = (target) => {
  if($(target).prop('checked')) {
    $('.currency_type').prop('checked', true);
    $('.custom_unit_dropdown').val('').change();
    handle_custom_unit_dropdown(target.value, $('.custom-unit-dropdown-div'), $('.custom_unit_dropdown'));
    hide_allowances_input_fields(`.custom-unit-button, .custom-unit-dropdown-div`);
  } else {
    $('.custom-unit-button').removeClass('d-none');
    $('.custom_unit_type').prop('checked') ? $('.custom-unit-dropdown-div').removeClass('d-none') : hide_allowances_input_fields(`.custom-unit-dropdown-div`)
  }
}

const apply_custom_date_and_select2 = () => {
  apply_custom_date_input()
  $('.select_designation_employees').select2({ theme: 'bootstrap4' });
  $('.select_designations_group_employees').select2({ theme: 'bootstrap4' });
}

const append_employees_list_row_for_designation = (nested_form, append_before, designation) => {
  let last_row_id = $('.select_designation_employees:last').attr('id')
  $.ajax({
    type: 'GET',
    url: '/employee_lists/new_row',
    data: {
      row: parseInt(last_row_id?.match(/\d+/)?.at(0) || -1) + 1,
      designation: designation,
      nested_form: nested_form,
      employee_list_for: 'designation'
    },
    success(response) {
      let new_row_id = $(response).find('select')[0].id
      $(append_before).parent().before(response);
      apply_custom_date_and_select2();
      select2_avatar_options()
      append_hidden_input($(`#${new_row_id}`));
      remove_selected_options_from_new_row(new_row_id, $('.select_designation_employees'))
    }
  });
}

const append_employees_list_row_for_designations_group = (nested_form, append_before, group, level_ids = null) => {
  let last_row_id = $('.select_designations_group_employees:last').attr('id')
  $.ajax({
    type: 'GET',
    url: '/employee_lists/new_row',
    data: {
      row: parseInt(last_row_id?.match(/\d+/)?.at(0) || -1) + 1,
      group: group,
      nested_form: nested_form,
      employee_list_for: 'designations_group',
      level_ids: level_ids,
    },
    success(response) {
      let new_row_id = $(response).find('select')[0].id
      $(append_before).parent().before(response);
      apply_custom_date_and_select2();
      select2_avatar_options()
      append_hidden_input($(`#${new_row_id}`));
      remove_selected_options_from_new_row(new_row_id, $('.select_designations_group_employees'))
    }
  });
}

const load_month_year_picker = () => {
  $('.month-year-picker').datetimepicker({
    format: 'MMM YYYY',
    viewMode: 'months',
    useCurrent: false,
    icons: {
      previous: "fas fa-chevron-left",
      next: "fas fa-chevron-right",
    }
  })
}

const append_designation_employees = () => {
  const designation = selected_designation()
  $.ajax({
    type: 'GET',
    url: '/employee_lists/new',
    data: {
      employee_list_for: 'designation',
      designation: designation
    },
    success(response) {
      $('#list-nested-form-for-designation').html(response)
      apply_custom_date_and_select2()
      select2_avatar_options()
      append_hidden_input($('.select_designation_employees'));
    }
  });
}

const hide_allowances_input_fields = (input_fields) => {
  input_fields.split(', ').forEach((input_field) => {
    $(input_field)?.addClass('d-none');
  })
}

const remove_option_and_update_hidden_field = (e, select_elem_class) => {
  let selected_elem_id = e.target.id
  let selected_element = $(`#${selected_elem_id}`)
  remove_selected_option_from_select_elements(selected_elem_id, selected_element, select_elem_class)
  update_hidden_input_with_selected_option(selected_element);
}

const remove_selected_option_from_select_elements = (selected_elem_id, selected_element, select_elem_class) => {
  $(select_elem_class).each(function () {
    if (this.id == selected_elem_id)  return true
    if (selected_element.find('input').val() != '') {
      this.append(selected_employee_option(selected_element))
    }
    $(this).find(`[value=${selected_element.find(':selected').val()}]`)[0].remove()
  })
}

const selected_employee_option = (selected_element) => {
  return $('<option>', {
    ['data-img_src']: selected_element.find('input').attr('data-img_src'),
    value: selected_element.find('input').val(),
    text: selected_element.find('input').attr('text')
  })[0]
}

const update_hidden_input_with_selected_option = (selected_element) => {
  selected_element.find('input').attr({
    ['data-img_src']: selected_element.find(':selected').attr('data-img_src'),
    value: selected_element.find(':selected').val(),
    text: selected_element.find(':selected').text()
  })
}

const append_removed_option_in_select_elements = (e, form_class, dropdown) => {
  let removed_element = $(e.target).closest(form_class)
  dropdown.each(function () {
    if (removed_element.find('input').val() != '') {
      this.append(selected_employee_option(removed_element))
    }
  })
}

const remove_selected_options_from_new_row = (new_row_id, dropdown) => {
  option_values = []
  dropdown.each(function () {
    if ($(this).find(':selected').val() != '') {
      option_values.push($(this).find(':selected').val())
    }
  })
  if (option_values.length > 0 ) {
    let options = $(`#${new_row_id}`)[0].options
    for (let i = 0; i < options.length; i++) {
      if (option_values.includes(options[i].value)) {
        options[i].remove()
        i--
      }
    }
  }
}

const append_hidden_input = (select_element) => {
  $('<input>').attr({
    type: 'hidden',
    value: '',
    text: ''
  }).appendTo(select_element);
}

$(document).on('click', '.add-allowance-end-date-btn', (e) => {
  e.stopPropagation();
  let table_id = $(e.target).closest('.table').attr('id').split('-')[0]
  let button_id = e.currentTarget.id
  $('#allowance-end-date-form').modal('show');
  allowance_end_date_modal_show(button_id, table_id)
})

const filter_allowances = () => {
  let designations = $('#select-designation').val();
  let type = $('#select-allowance-type').val();
  let status = $('#select-allowance-status').val();
  if (window.location.pathname.split('/')[1] == 'allowances') {
    $.ajax({
      type: 'GET',
      url: '/allowances',
      dataType: 'script',
      data: { designation: designations, type: type, status: status }
    })
  }
}

const allowance_end_date_modal_show = (button_id, table_id) => {
  $(document).on('shown.bs.modal', '#allowance-end-date-form', (e) => {
    let entity_type = button_id.split('-')[1]
    let entity_id = button_id.split('-')[4]
    if (entity_type == 'OpenAllowance'){
      $('#add-allowance-end-date-form').attr('action', `/open_allowances/${entity_id}`)
    } else if ( entity_type == 'EmployeeList') {
      $(e.target).find('h4').text('Add Applicable Till Date')
      $('#add-allowance-end-date-form').addClass('revision')
      $('#add-allowance-end-date-form').attr('action', `/employee_lists/${entity_id}`)
    } else if (entity_type == 'GroupAllowance') {
      $('#add-allowance-end-date-form').attr('action', `/group_allowances/${entity_id}`)
    } else {
      $('#add-allowance-end-date-form').attr('action', `/designation_allowances/${entity_id}`)
    }


    if (table_id == 'revisions') {
      $('#add-allowance-end-date-form').addClass('revision')
    }
  })
}

const append_messages_inside_modal = (e, modal, allowance_type, select_element) => {
  const { errors, success, warning_message} = e.detail[0];
  if (errors.length) {
    append_errors(errors, 'Cannot Add due to following issues:', modal)
  }
  if (warning_message) {
    const show_warning_message = `<div class='alert alert-warning w-100'><h6 class='pt-2'>${warning_message}.</h6></div>`;
    warning_div = modal.find('#warning_message')
    warning_div.removeClass('d-none')
    warning_div.html(show_warning_message);
    $('#merge_request').val(true)
  }
  if (success.length) {
    $('#designation_allowances_csv').siblings('label').html('Choose File')
    $('.alert-container').removeClass('d-none');
    let selected = select_element.find('option:selected').val()
    let entity_type =  e.currentTarget.action.split('/')[3]
    modal.modal('toggle');
    modal.find('form').trigger('reset');
    level_ids_dropdown = modal.find('.level-ids');
    if(level_ids_dropdown) {
      level_ids_dropdown.empty();
      level_ids_dropdown.prop('disabled', true);
    }
    const header_success_message = `<p class='text-success  px-4 pt-4'> <span class='fas fa-check-circle fa-lg mx-2'>\
                                </span><strong> ${success} </strong>\
                                <button class='close' data-dismiss='alert' type='button' id='close-success-alert'> \
                                <span aria-hidden='true'>×</span> \
                                <span class='sr-only'>Close</span> \
                                </button> \
                                </p>`;
    const success_message = `<div class='alert alert-success w-100'>\
                            <h6> ${header_success_message} </h6>`
    "</div>";
    $('.alert-container').html(success_message);

    if (allowance_type == 'end-date-form')  {
      end_date_modal_submission(entity_type)
    } else if (!(allowance_type == 'revision')) {
      select_element.find(`[value = ${selected}]`).remove()
      append_employee_details_in_allowance_datatable(allowance_type);
    } else {
      append_employee_details_in_allowance_revision_table()
    }

    $(e.target).find('input[type="checkbox"]').prop('checked', false).trigger('change')
    $('.continuation-toggle').prop('checked', true).trigger('change')
    $('#list-nested-form-for-designation').html('')
    $('#list-nested-form-for-group').html('')
    $('.alert-container').fadeTo(5000, 1000).slideUp(1000, function () {
      $('.alert-container').slideUp(1000);
    });
    render_active_allowance_tab()
  }
}

const append_messages_inside_modal_for_multiple_employees = (e, modal, form_type) => {
  const { errors, saved_list, hr_request_creation } = e.detail[0];
  if (errors.length) {
    append_errors(errors, 'Some Entities were not added. The following issues were found:', modal)
  }
  if (saved_list.length) {
    success_div = modal.find('#ajax_success')
    $('.alert-container').removeClass('d-none');
    remove_saved_entities(success_div, saved_list, form_type, hr_request_creation)
    if (errors.length === 0) {
      modal.modal('toggle');
      remove_saved_entities($('.alert-container'), saved_list, form_type, hr_request_creation)
    }
    if (form_type == 'employee_list') {
      append_employee_details_in_allowance_revision_table()
    } else {
      append_employee_details_in_allowance_datatable(form_type)
    }
    render_active_allowance_tab()
  }
}

const append_errors = (errors, message, modal) => {
  const header_message = `<p class='text-danger px-4 pt-4'> <span class='fas fa-times-circle fa-lg mx-2'>\
                            </span> <strong>${message}</strong>\
                            <button class='close' type='button' id='close-errors-alert'> \
                              <span aria-hidden='true'>×</span> \
                              <span class='sr-only'>Close</span> \
                            </button> \
                          </p>`;
  error_div = modal.find('#ajax_errors')
  error_div.removeClass('d-none')
  error_div.html(header_message);
  for (let error of Array.from(errors)) {
    const error_message = `<div class='text-danger px-5'> \
                              <ul>
                                <li> ${error.charAt(0).toUpperCase() + error.slice(1) } </li> \
                              </ul>\
                          </div>`;
    error_div.append(error_message);
  }
}

const remove_saved_entities = (success_div, saved_list, form_type, hr_request_creation) => {
  var display_message = ''
  if (hr_request_creation) {
    display_message = 'Request submitted successfully for:'
  } else {
    display_message = 'Successfully added:'
  }
  const header_success_message = `<p class='text-success  px-4 pt-4'> <span class='fas fa-check-circle fa-lg mx-2'>\
                                    </span><strong>${display_message}</strong>\
                                    <button class='close' data-dismiss='alert' type='button' id='close-success-alert'> \
                                    <span aria-hidden='true'>×</span> \
                                    <span class='sr-only'>Close</span> \
                                    </button> \
                                  </p>`;

  let entity = '';
  if (form_type == 'employee_list') {
    if (window.location.pathname.split('/')[1] == 'group_allowances') {
      form_name = '.employee-list-form-for-designations_group'
    } else {
      form_name = '.employee-list-form-for-designation'
    }
  } else {
    form_name = '.allowance-details-form'
  }
  for (let entity_title of Array.from(saved_list)) {
    entity += `<div class='text-success px-5'><ul><li> ${entity_title}</li></ul> </div>`;
    $(`span[title='${entity_title}']`).closest(form_name).remove()
  }
  const success_message = `<div class='alert alert-success w-100'>\
                           <h6> ${header_success_message} </h6>` +
                           entity +
                           "</div>";
  success_div.removeClass("d-none");
  success_div.html(success_message);
  success_div.fadeTo(5000, 1000).slideUp(1000, function () {
    success_div.slideUp(1000);
  });
}

const end_date_modal_submission = (entity_type) => {
  if ($('#add-allowance-end-date-form').attr('class') ==  'revision') {
    append_employee_details_in_allowance_revision_table()
  } else {
    append_employee_details_in_allowance_datatable(entity_type)
  }
}

const append_employee_details_in_allowance_datatable = (allowance_type) => {
  let table_id = ''
  let path = ''
  let open_allowance
  let allowance_category
  if (allowance_type == 'open_allowances') {
    allowance_category = 'open'
    open_allowance = true
    table_id = 'active-allowance-table'
    path = open_allowance_path
  } else if (allowance_type == 'designation_allowances') {
    allowance_category = 'designation'
    open_allowance = false
    table_id = 'active-designation-allowance-table'
    path = designation_allowance_path
  } else if (allowance_type == 'group_allowances') {
    allowance_category = 'group'
    open_allowance = false
    table_id = 'active-designation-allowance-table'
    path = group_allowance_path
  }
  let allowance_id = window.location.pathname.split('/')[2]
  $.ajax({
    type: 'GET',
    url: `/allowances/${allowance_id}`,
    dataType: 'script',
    data: { table_id: table_id, path: path, open_allowance: open_allowance, allowance_category: allowance_category}
  });
}

const append_employee_details_in_allowance_revision_table = () => {
  $.ajax({
    type: 'GET',
    url: window.location.pathname,
    dataType: 'script',
  });
}

const handle_custom_unit_dropdown = (selected_value, dropdown_div, dropdown) =>{
  if (selected_value == 'custom_unit'){
    dropdown_div.removeClass('d-none')
    dropdown.prop('required',true)
  } else {
    dropdown_div.addClass('d-none')
    dropdown.prop('required',false)
  }
}

$(document).on('shown.bs.tab', (e) => {
  if(window.location.pathname.split('/')[1] == 'allowances' && window.location.pathname.split('/')[2]){
    if($('#open-allowance-tab').hasClass('active')){
      render_open_allowance_table()
    }
    else if($('#designation-allowance-tab').hasClass('active')){
      render_designation_allowance_table()
    }
  }
});

const render_open_allowance_table = () =>{
 let allowance_id = window.location.pathname.split('/')[2]
 $.ajax({
   type: 'GET',
   url: open_allowance_path,
   dataType: 'script',
   data: { allowance_id: allowance_id }
 });
}

const render_employee_select_box = (select_element , allowance_id, create_tag = false, new_row_id = '') => {
  $(`${select_element}`).select2({
  placeholder: 'Please Select Employee',
  allowClear: true,
  minimumInputLength: 1,
  ajax: {
    url: '/employees',
    dataType: 'json',
    type: "GET",
    data: (params) => {
      let query_params = {
        allowance_id:  allowance_id,
        employee_search: params.term
      }
      return query_params;
    },
    processResults:  (data, params) => {
      return {
          results: remove_selected_option_from_employee_list(data.employees)
      };
    }
  },
  ...(create_tag) && {
    createTag:  (params) => {
    let term = $.trim(params.term);
    if (term === '') {
        return null;
    }
    return {
        id: new_row_id,
        text: ''
    };
  }},
  templateResult: (data) => {
    let employee_detail = `${data.external_id}. ${data.full_name} - ${data.business_unit}`
    let $state = $(
      `<div class="row p-0 ml-2 d-flex flex-nowrap">
          <span title="${employee_detail}"> </span> ${data.employee_img}
          <div class="d-flex align-self-center ml-2 select-box-font-size"> ${employee_detail} </div>
       </div>`
    );
    return data.external_id !=undefined ?  $state : data
  },
  templateSelection: (data) => {
    let employee_detail = `${data.external_id}. ${data.full_name} - ${data.business_unit}`
    let $state = $(
      `<div class="row p-0 ml-2 d-flex flex-nowrap">
         <span title="${employee_detail}"> </span> ${data.employee_img}
         <div class="d-flex align-self-center ml-2 select-box-font-size"> ${employee_detail} </div>
       </div>`
    );
    return data.external_id !=undefined ?  $state : 'Please Select Employees'
  }
}).on("select2:select", (e) => {
  $(`#${e.delegateTarget.id}`).attr('employee-external-id',e.currentTarget.value)
}).on("select2:unselecting", (e) => {
  selected_employee_list = selected_employee_list.filter(item => item !== e.currentTarget.getAttribute('employee-external-id'))
})}

const render_designation_allowance_table = () =>{
 let allowance_category = $('#allowance-category').val();
 let allowance_id = window.location.pathname.split('/')[2]
 $.ajax({
    type: 'GET',
    url: allowance_category == 'Designation' ? designation_allowance_path : group_allowance_path,
    dataType: 'script',
    data: { allowance_id: allowance_id, allowance_category: allowance_category }
  });
}

const render_active_allowance_tab = () => {
  if(window.location.pathname.split('/')[1] == 'allowances' && window.location.pathname.split('/')[2]){
    if($('#open-allowance-tab').hasClass('active')){
      render_open_allowance_table()
    }
    else if($('#designation-allowance-tab').hasClass('active')){
      render_designation_allowance_table()
    }
  }
}

const remove_selected_option_from_employee_list = (records) =>{
  if(records.length && selected_employee_list.length){
    let employee_id_obj={};
    let filteredArray=[];
    selected_employee_list.forEach((element) => {
      employee_id_obj[element] = true;
    });
    records.forEach((employee) => {
      if (!employee_id_obj[employee.id]) filteredArray.push(employee);
    });
    return  filteredArray
  }
  return records
}

const append_level_employees = (e, allowance_type = "Group") => {
  const group = selected_group(allowance_type);
  const levelIds = selected_levels(e);
  $.ajax({
    type: "GET",
    url: "/employee_lists/new",
    data: {
      employee_list_for: "level_ids",
      group: group,
      level_ids: levelIds,
    },
    success(response) {
      allowance_type === "Group"
        ? $("#list-nested-form-for-group").html(response)
        : $("#list-nested-form-for-rank").html(response);
      apply_custom_date_and_select2();
      select2_avatar_options();
      append_hidden_input($(".select_designations_group_employees"));
    }
  });
};

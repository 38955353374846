const initialize_datetimepicker = () => {
  $(".custom-date-input").datetimepicker({
    format: "DD MMM, YYYY",
    icons: {
      previous: "fas fa-chevron-left",
      next: "fas fa-chevron-right",
    },
  });
}

$(document).on('click', '#remove-grouped-designation', (e) => {
  if (confirm('Removing designation from group will revert all the amount of group allowance paid to the employees of this designation! \nAre you sure you want to remove this designation?')) {
    let designation_group_id = $(e.currentTarget).data('designations-group-id')
    $.ajax({
      type: 'Delete',
      url: `designations_groups/${designation_group_id}`
    })
  }
})

$(document).on('click', '#create-designations-group-btn', (e) => {
  $.ajax({
    type: 'Get',
    url: 'groups/new',
    dataType: 'JSON',
    success(response) {
      $('#create-designations-group').html(response.html)
      initialize_datetimepicker()
      $('#create-designations-group').modal('toggle')
    }
  })
})

$(document).on('click', '#add-designations-in-group-btn', (e) => {
  let group_id = $(e.currentTarget).closest('td').data('group-id')
  $.ajax({
    type: 'Get',
    url: `groups/${group_id}/edit`,
    dataType: 'JSON',
    success(response) {
      $('#create-designations-group').html(response.html)
      initialize_datetimepicker()
      $('#create-designations-group').modal('toggle')
    }
  })
})

$(document).on('hidden.bs.modal', '#create-designations-group', (e) => {
  $('#create-designations-group').html('')
})

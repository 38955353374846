document.addEventListener('DOMContentLoaded', () => {
  $('#salary-month').select2();
  $('#salary_month_drop').datetimepicker({
    format: 'MMM YYYY',
    viewMode: 'months',
    maxDate: moment($('#salary_month_drop').data('max-month')),
    icons: {
      previous: "fas fa-chevron-left",
      next: "fas fa-chevron-right",
    }
  })

  $('#salary-logs-table').DataTable({
    fixedHeader: true,
    pageLength: 100,
    processing: true,
    serverSide: true,
    ajax: {
      'type': 'get',
      'url': '/employee_salaries',
      'dataType': 'json',
      'data': (data) => {
        data.page = parseInt($('#salary-logs-table').DataTable().page.info().page) + 1
        data.per_page = $('#salary-logs-table').DataTable().page.info().length
        data.search = { text: data.search.value, business_unit: $('#select-business-unit-filter').val(),  user_type: $('#select-user-type-filter').val() }
        data.order = $('#salary-logs-table').DataTable().order()[0][0]
        data.direction = $('#salary-logs-table').DataTable().order()[0][1]
      }
    },
    "drawCallback": () => {
      if ($('#salary-logs-table').DataTable().rows().count()) {
        $('#salary-logs-table tr:last').attr('id', 'net-total-row');
      }
    },
    deferRender: true,
    columnDefs: [
      {
        'targets': 1,
        'createdCell': (td, cellData, rowData, row, col) => {
          if (rowData[18]) {
            $(td).closest('tr').addClass('cursor-pointer')
            $(td).closest('tr').attr('id', 'payroll-sheet-row')
            $(td).closest('tr').attr('data', rowData[18].salary_log_id)
            $(td).closest('tr').addClass(rowData[18].row_color_code)
            $(td).addClass('employee-profile-page text-break')
            $(td).attr('onClick', `window.open('/employees/${rowData[18].employee_external_id}', '_blank')`)
          }
        }
      },
      {
        'targets': 16,
        'createdCell': (td, cellData, rowData, row, col) => {
          if (rowData[18] && rowData[18].salary_partially_held === true) {
            $(td).addClass('bg-light-red')
          }
        }
      },
      {
        'targets': 14,
        'createdCell': (td, cellData, rowData, row, col) => {
          $(td).addClass('text-nowrap disable-propagation')
        }
      }
    ]
  });
  debounceSearch('#salary-logs-table')
});

$(document).on('change', '#salary-month, #select-business-unit-filter, #select-user-type-filter', () => {
  if (window.location.pathname.split("/")[1] == "employee_salaries") {
    $.ajax({
      type: "GET",
      url: "/employee_salaries",
      dataType: "script",
      data: { salary_month: $("#salary-month").find("option:selected").text() },
    });
  }
});

$(document).on('change', '#salary_logs_csv', (e) => {
  const fileName = $(e.target).val().split("\\").pop();
  if (fileName.replace(/^.*\./, '') === 'csv') {
    $(e.target).siblings(".custom-file-label").addClass("selected").html(fileName);
    $(e.target).parent().find('p').remove();
    $('#import-salary-logs-btn').attr("disabled", false);
  } else {
    $('#import-salary-logs-btn').attr("disabled", true);
    $(e.target).parent().append("<p id='error' style='color: red'>Please choose csv file.</p>");
    setTimeout(() => {
      $('#import-salary-logs-btn').attr("disabled", true);
      $('#error').remove();
    }, 5000);
    e.preventDefault();
  }
});

$(document).ready(function() {
  $('#additional_payments_table').DataTable();
  $('.date-picker').datetimepicker( {
    format: "MMM YYYY",
    viewMode: "months",
    useCurrent: false,
    date: $('.date-picker').data('default'),
    minDate: $('.date-picker').data('min-date'),
    maxDate: $('.date-picker').data('max-date'),
    icons: {
      previous: "fas fa-chevron-left",
      next: "fas fa-chevron-right",
    }
  });
  window.scrollTo({ top: 0, behavior: 'smooth' });
});

$(document).on('change', '#additional-payments-csv', (e) => {
  const fileName = $(e.target).val().split("\\").pop();
  if (fileName.replace(/^.*\./, '') === 'csv') {
    $(e.target).siblings(".custom-file-label").addClass("selected").html(fileName);
    $('#import-additional-payments-btn').attr("disabled", false);
  } else {
    $('#import-additional-payments-btn').attr("disabled", true);
    $(e.target).siblings(".custom-file-label").addClass("selected").html(fileName);
    $(e.target).parent().append("<p id='error' style='color: red'>Please choose csv file.</p>");
    setTimeout(() => {
      $('#import-additional-payments-btn').attr("disabled", true);
      $('#error').remove();
    }, 5000);
    e.preventDefault();
  }
});

$(document).on('click', '#payments-settings-tab', (e)=> {
  $.ajax({
    type: 'GET',
    url: '/payment_types',
    dataType: 'JSON',
    success(response) {
      $('#payments-settings').html(response.html);
      $('#payment_types_table').dataTable({
        "order": [], "pageLength": 10
      });
    },
    error(response) {
      show_header_message_in_alert_container(response.errors);
    }
  });
})

$(document).on('click', '.edit-payment-type-btn', (e) => {
  let payment_type_id = e.target.getAttribute('payment_type_id');
  $.ajax({
    type: 'GET',
    url: `/payment_types/${payment_type_id}/edit`,
    dataType: 'JSON',
    success(response) {
      $(`#update_payment_type_${payment_type_id}`).html(response.payment_type_form_response);
    }
  });
});

$(document).on('ajax:error ajax:success', '#payment_type_form', (e) => {
  let form_action = e.target.getAttribute('action');
  let modal_id = form_action.split('/').length === 3 ? `#update_payment_type_${form_action.split('/').at(-1)}` : '#add_new_payment_type'
  append_message(e, $(modal_id));
});

$(document).on('click', '.delete_payment_type_btn', (e) => {
  e.preventDefault();

  let payment_type_id = e.target.getAttribute('payment_type_id');
  if (confirm('Are you sure you want to delete this payment type?')) {
    $.ajax({
      type: 'DELETE',
      url: `/payment_types/${payment_type_id}`,
      dataType: 'JSON',
      success(response) {
        show_header_message_in_alert_container(response.success);
        $('#payment_types_table').DataTable().clear().destroy();
        $('#payment_types_table_body').html(response.payment_type_table_response);
        $('#payment_types_table').DataTable({
          fixedHeader: true,
          order: [], "pageLength": 10
        });
      },
      error(response) {
        show_header_message_in_alert_container(response.errors);
      }
    });
  } else {
    return false;
  }
});


const show_header_message_in_alert_container = (message) => {
  const header_success_message = `<p class='text-success px-4 pt-4'> <span class='fas fa-check-circle fa-lg mx-2'></span>
                                    <strong> ${message} </strong>\
                                    <button class='close' data-dismiss='alert' type='button' id='close-success-alert'> \
                                      <span aria-hidden='true'>×</span> \
                                      <span class='sr-only'>Close</span> \
                                    </button> \
                                  </p>`;
  const success_message = `<div class='alert alert-success w-100'><h6> ${header_success_message} </h6></div>`
  $('.alert-container').html(success_message);
  show_alert_container();
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

const append_message = (e, modal) => {
  const { errors, success } = e.detail[0];
  const errorDiv = modal.find('#ajax_errors');
  if (errors.length === 0) {
    close_modal(modal);
    show_header_message_in_alert_container(success);
    $.ajax({
      type: 'GET',
      url: `/payment_types`,
      dataType: 'script'
    })
    modal.find('form').trigger('reset');
    errorDiv.addClass('d-none');
    errorDiv.html('');
  } else if (success.length === 0) {
    append_errors(errors, 'Cannot create payment type because', modal)
  }
}

const append_errors = (errors, message, modal) => {
  const header_message = `<p class='text-danger py-4'> <span class='fas fa-times-circle fa-lg mx-2 float-left'></span>
                            <strong class='float-left ml-2'>${message}</strong>\
                            <button class='close mr-3' type='button' id='close-errors-alert'> \
                              <span aria-hidden='true'>×</span> \
                              <span class='sr-only'>Close</span> \
                            </button> \
                          </p>`;
  error_div = modal.find('#ajax_errors')
  error_div.removeClass('d-none')
  error_div.html(header_message);
  for (let error of Array.from(errors)) {
    const error_message = `<div class='text-danger float-left'> \
                              <ul>
                                <li> ${error.charAt(0).toUpperCase() + error.slice(1)} </li> \
                              </ul>\
                          </div>`;
    error_div.append(error_message);
  }
}

$(document).on('change.select2', '#select-business-unit-filter', function() { filter_additional_payment_table() });
const filter_additional_payment_table = () => {
  let business_unit_id = $('#select-business-unit-filter').val();
  if (window.location.pathname.split('/')[1] == 'additional_payments') {
    $.ajax({
      type: 'GET',
      url: '/additional_payments',
      dataType: 'script',
      data: { business_unit_id: business_unit_id }
    });
  }
}

$(document).on('click', '.additional_payment_row', (e) => {
  let employee_id = e.currentTarget.getAttribute('data-target').split('_').at(-1);
  if (!$.fn.DataTable.isDataTable(`#additional_payment_table_${employee_id}`)) {
    $(`#additional_payment_table_${employee_id}`).DataTable({
      fixedHeader: true,
      pageLength: 10,
      order: []
    });
  }
});

$(document).on('hidden.bs.modal', '.modal', function () {
  let modalId = $(this).attr('id');
  let employeeId = modalId.split('_').at(-1);
  if ($.fn.DataTable.isDataTable(`#additional_payment_table_${employeeId}`)) {
    $(`#additional_payment_table_${employeeId}`).DataTable().destroy();
  }
});

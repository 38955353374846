document.addEventListener('DOMContentLoaded', () => {
  render_payroll_sheet()
  $('#review-history').DataTable({
    order: [[ 3, 'desc' ]]
  })
  $('.fa-ellipsis-h').tooltip({
    template: '<div class="tooltip" role="tooltip">\
                <div class="tooltip-arrow"></div>\
                <div class="tooltip-inner salary-slips-tooltip"></div>\
               </div>' })
  disable_toggle($('.salary-generation-toggle'), '.fetch-data-toggle')
  if (localStorage.getItem('redirect-from') == 'mentioned-notification') {
    let employee_salary_id = localStorage.getItem('employee-salary-id')
    let employee_external_id = localStorage.getItem('employee-external-id')
    if (employee_salary_id == null || employee_external_id == null) { return }

    show_comment_box(employee_salary_id, employee_external_id)
    clear_local_storage()
  }
  $('#bulk-resolve-btn').prop('disabled', 1);
});


const initialize_datepicker = () => {
  document.querySelectorAll('.datepicker').forEach(e => {
    $(e).datetimepicker( {
      format: "MMM YYYY",
      viewMode: "months",
      useCurrent: false,
      minDate: $(e).data('min-date'),
      icons: {
        previous: "fas fa-chevron-left",
        next: "fas fa-chevron-right",
      }
    });
  })
}

$(document).on('click', '#payroll-sheet-row, #salary-logs-row', (e) => {
  if ($('.modal').css('display') == 'block') { return }

  $('.side-pane').addClass('display-center')
  $('.side-pane').css('width', '85%')
  $('.side-pane-layer').css('display', 'block')
  $('body').css('overflow', 'hidden')
  $.ajax({
    type: 'GET',
    url: `/employee_salaries/${$(e.target.parentElement).attr('data')}`,
    dataType: 'JSON',
    success(response) {
      $('.side-pane').removeClass('display-center')
      $('.side-pane').html(response.html);
      document.querySelectorAll('.nav-tabs').forEach( e => {
        e.firstChild.classList.add('active')
      })
      document.querySelectorAll('.tab-content').forEach( e => {
        e.firstChild.classList.add('show', 'active')
      })
    }
  });
})

$(document).on('click', '#pane-close-btn', (e) => {
  $('.side-pane').html("<div class='spinner-grow text-dark' role='status'></div>\
                        <span class='ml-2'>Loading...</span>");
  $('.side-pane').css('width', '0px')
  $('.side-pane-layer').css('display', 'none')
  $('body').css('overflow', 'auto')
})

$(document).on('keyup', (e) => {
  if(e.key == 'Escape') {
    $('.side-pane').html("<div class='spinner-grow text-dark' role='status'></div>\
                          <span class='ml-2'>Loading...</span>");
    $('.side-pane').css('width', '0px')
    $('.side-pane-layer').css('display', 'none')
    $('body').css('overflow', 'auto')
    $('#review-history-modal').modal('hide')
  }
})

$(document).on('click', '#hold-salary-btn', (e) => {
  e.stopPropagation()
  let target_item = $(e.target)
  if (confirm(`This action will hold salary of ${target_item.closest('.dropdown-menu').attr('emp_name')} from current month till you unhold it.\nAre You Sure that you want to hold it?`)) {
    $.ajax({
      type: 'Put',
      url: `/employee_salaries/${target_item.closest('.dropdown-menu').attr('data')}`,
      data: { on_hold: true },
      success(response) {
        window.location.href = '/employee_salaries/employee_salaries_sheet';
      },
      error(error) {
        window.location.href = '/employee_salaries/employee_salaries_sheet';
      }
    })
  }
})

$(document).on('click', '#unhold-salary-btn', (e) => {
  e.stopPropagation()
  let target_item = $(e.target)
  if (confirm(`This action will unhold salary of ${target_item.closest('.dropdown-menu').attr('emp_name')} from current month.\nAre You Sure that you want to unhold it?`)) {
    $.ajax({
      type: 'Put',
      url: `/employee_salaries/${target_item.closest('.dropdown-menu').attr('data')}`,
      data: { on_hold: false },
      success(response) {
        window.location.href = '/employee_salaries/employee_salaries_sheet';
      },
      error(error) {
        window.location.href = '/employee_salaries/employee_salaries_sheet';
      }
    })
  }
})

$(document).on('click', '#unapprove-btn', (e) => { update_approval_status(e, 0) })
$(document).on('click', '#approve-btn', (e) => { update_approval_status(e, 1) })

const update_approval_status = (e, status) => {
  e.stopPropagation()
  let target_item = $(e.target)
  $.ajax({
    type: 'Put',
    url: `/employee_salaries/${target_item.closest('.dropdown-menu').attr('data')}`,
    data: { status: status },
    success(response) {
      window.location.href = '/employee_salaries/employee_salaries_sheet';
    },
    error(error) {
      window.location.href = '/employee_salaries/employee_salaries_sheet';
    }
  })
}

$(document).on('click', '.salary-sheet-actions-button', (e) => {
  initialize_manual_payment_toggle()
})


$(document).on('change', '.manual-payment-toggle', (e) => {
  let target_toggle = $(e.target)
  let form = target_toggle.closest('.manual-payment-form')
  let total_paybale_amount = form.find('#manually_payable_amount').attr('data-net-salary')
  let amount_field =  form.find('.manual_payment_amount')
  if(target_toggle.prop('checked')) {
    amount_field.val(total_paybale_amount)
    amount_field.attr('readonly', true)
  } else {
    amount_field.attr('readonly', false)
  }
})

$(document).on('click', '#mark-all-as-paid-btn', (e) => {
  let foreign_salary_ids = []
  let selected_checkboxes = $('#foreign-salary-table input:checkbox:checked')
  selected_checkboxes.each(index => {
    foreign_salary_ids.push(parseInt($(selected_checkboxes[index]).data('foreign-salary-id')))
  })
  $.ajax({
    type: 'Post',
    url: '/employee_salaries/foreign_salary_payment_details',
    data: { foreign_salary_ids: foreign_salary_ids, multiple: true },
    success(response) {
      window.location.href = '/foreign_salaries';
    }
  })
})

$(document).on('change', '.salary-generation-toggle', (e) => {
  let target_toggle = $(e.target)
  disable_toggle(target_toggle, '.fetch-data-toggle')
})

$(document).on('change', '.fetch-data-toggle', (e) => {
  let target_toggle = $(e.target)
  disable_toggle(target_toggle, '.salary-generation-toggle')
})

$(document).on('change', '.allow-individual-entities-generation-toggle', (e) => {
  let toggle = e.target
  let settings = $(toggle).closest('#salary-operations-settings')
  let settings_togggles = settings.find(".entity-generation-toggles input[type='checkbox']")

  if (toggle.checked){
    settings_togggles.prop('checked', false).change()
    settings_togggles.removeAttr('disabled').change()
  } else {
    settings_togggles.prop('checked', true).change()
    settings_togggles.attr('disabled', 'disabled').change()
  }
})

const disable_toggle = (target_toggle, toggle_class) => {
  target_toggle.closest('#salary-operations-settings')
               .find(`${toggle_class}`)
               .attr('disabled', target_toggle.prop('checked'))
}

$(document).on('click', '.fa-ellipsis-h', (e) => { $('[data-toggle="dropdown"]').tooltip('hide'); })

$(document).on('click', '#add-comment-box', (e) => {
  let employee_salary_id = $(e.target).closest('.dropdown-menu').attr('data')
  get_and_display_employee_salary_comments(employee_salary_id)
})

$(document).on('click', '.mentioned-notification', (e) => {
  let target = $(e.target)
  let employee_salary_id = target.data('employee-salary-id')
  let employee_external_id = target.data('employee-external-id')

  if (window.location.pathname == '/employee_salaries/employee_salaries_sheet') {
    show_comment_box(employee_salary_id, employee_external_id)
  } else {
    localStorage.setItem('redirect-from', 'mentioned-notification')
    localStorage.setItem('employee-salary-id', employee_salary_id)
    localStorage.setItem('employee-external-id', employee_external_id)
    window.location.href = '/employee_salaries/employee_salaries_sheet'
  }
})

$(document).on('hidden.bs.modal', '#comment-box', (e) => {
  let modal_body = $('#comment-box').find('.modal-body')
  modal_body.removeClass('flex-column-reverse')
  modal_body.addClass('justify-content-center align-items-center')
  modal_body.html("<div class='spinner-grow text-dark' role='status'></div>\
                   <span class='ml-2'>Loading...</span>" )
})

$(document).on('click', '#load-more-comments', (e) => {
  let page_no = $(e.target).data('page-no');
  let employee_salary_id = $(e.target).closest('#comments').data('employee-salary-id');
  $(e.target).remove();
  $('.load-more-comments-spinner').removeClass('d-none');
  $.ajax({
    type: 'Get',
    url: `/employee_salaries/${employee_salary_id}/comments`,
    data: { page_no: parseInt(page_no) + 1, load_more: true },
    error(err) {
      window.location.reload();
    }
  })
})

$(document).on('click', '#add_missing_pfs_btn', () => {
  initialize_datepicker()
  $('#missing_pfs_form').removeClass('d-none');
  $('.modal-footer').removeClass('d-none');
  $('#add_missing_pfs_btn').addClass('d-none');
})

$(document).on('click', '.modal-close-btn', () => {
  location.reload();
})

$(document).on('change', '#manual_payment_csv', (e) => {
  const fileName = $(e.target).val().split("\\").pop();
  if (fileName.replace(/^.*\./, '') === 'csv') {
    $(e.target).siblings(".custom-file-label").addClass("selected").html(fileName);
    $('#import-manual-payment-btn').attr("disabled", false);
  } else {
    $('#import-manual-payment-btn').attr("disabled", true);
    $(e.target).siblings(".custom-file-label").addClass("selected").html(fileName);
    $(e.target).parent().append("<p id='error' style='color: red'>Please choose csv file.</p>");
    setTimeout(() => {
      $('#import-manual-payment-btn').attr("disabled", true);
      $('#error').remove();
    }, 5000);
    e.preventDefault();
  }
});

const show_comment_box = (employee_salary_id, employee_external_id) => {
  $('#payroll-sheet').DataTable().search(employee_external_id).draw()
  $('#comment-box').modal('show')
  get_and_display_employee_salary_comments(employee_salary_id)
}

const clear_local_storage = () => {
  localStorage.removeItem('redirect-from')
  localStorage.removeItem('employee-salary-id')
  localStorage.removeItem('employee-external-id')
}

const get_and_display_employee_salary_comments = (employee_salary_id) => {
  $.ajax({
    type: 'Get',
    url: `/employee_salaries/${employee_salary_id}/comments`,
    data: { page_no: 1, load_more: false },
    error(err) {
      window.location.reload();
    }
  })
}

$(document).on('change', '#select-user-type-filter, #select-business-unit-filter', () => { filters_payroll_sheet() });
$(document).on('change.select2', '#select-legends-payroll-sheet', () => { filters_payroll_sheet() });
$(document).on('order.dt', '#payroll-sheet', ()=>{ initializeSalaryOperationToggles() })


const filters_payroll_sheet = () => {
  if (window.location.pathname == '/employee_salaries/employee_salaries_sheet') {
    render_payroll_sheet()
  }
}

const render_payroll_sheet = () => {
  $('#payroll-sheet').DataTable().clear().destroy();
  $('#payroll-sheet').DataTable({
    lengthMenu: [[10, 50, 100, 500], [10, 50, 100, 500]],
    pageLength: 50,
    bDestroy: true,
    fixedHeader: true,
    processing: true,
    serverSide: true,
    "initComplete": () =>{
      initializeSalaryOperationToggles()
    },
    ajax: {
      'type': 'get',
      'url': '/employee_salaries/employee_salaries_sheet',
      'dataType': 'json',
      'data': (data) => {
        data.page = parseInt($('#payroll-sheet').DataTable().page.info().page) + 1
        data.per_page = $('#payroll-sheet').DataTable().page.info().length
        data.search = { text: data.search.value, business_unit: $('#select-business-unit-filter').val(), user_type: $('#select-user-type-filter').val(), filters: $('#select-legends-payroll-sheet').val(), status: $('#salary-status-filter').val() }
        data.order = $('#payroll-sheet').DataTable().order()[0][0]
        data.direction = $('#payroll-sheet').DataTable().order()[0][1]
      }
    },
    "drawCallback": () => {
      if($('#payroll-sheet').DataTable().rows().count()){
        $('#payroll-sheet tr:last').attr('id','net-total-row');
      }
    },
    deferRender: true,
    columnDefs: [
      {
        'targets': 0,
        'createdCell': (td, cellData, rowData, row, col) => {
          $(td).addClass('text-center')
          if(rowData[19]){
            if(rowData[19].completely_locked){
              $(td).append("<i class='fas fa-sm fa-lock px-2 d-block text-center'></i>")
            }
            else if(rowData[19].completely_ungenerated){
              $(td).append("<i class='fas fa-sm fa-exclamation-triangle px-2 d-block text-center'></i>")
            }
          }
        }
      },
      {
        'targets': 1,
        'createdCell': (td, cellData, rowData, row, col) => {
          if(rowData[19]){
            $(td).closest('tr').addClass(rowData[19].row_color_code)
            $(td).closest('tr').attr('id', 'payroll-sheet-row')
            $(td).addClass('employee-profile-page text-break')
            $(td).closest('tr').addClass('cursor-pointer')
            $(td).closest('tr').attr('data', rowData[19].emp_salary_id)
            $(td).attr('onClick', `window.open('/employees/${rowData[19].employee_external_id}')`),
            $(td).attr({
              'data-toggle': 'tooltip',
              'data-placement': 'right',
              'title': `${rowData[19].employee_designation}`
            })
          }
        }
      },
      ...Array.from({ length: 14 }, (_, index) => (
        {
          'targets': index + 2,
          'createdCell': (td, cellData, rowData, row, col) => {
            $(td).addClass('text-nowrap')
            payrollSheetCellWithState(td, rowData, index + 2)
          }
        }
      )),
      {
        'targets': 16,
        'createdCell': (td, cellData, rowData, row, col) => {
          if(rowData[19]){
            if(rowData[19].attributes_state_mapping['updated'].includes(16)){
              $(td).addClass('bg-light-orange')
            }
            if(rowData[19].salary_partially_held === true) {
              $(td).addClass('bg-light-red')
            }
            if (!rowData[19].completely_locked && !rowData[19].completely_ungenerated){
              $(td).append(attribute_state_icon(rowData[19].attributes_state_mapping, 16));
            }
          }
        }
      },
      {
        'targets': 17,
        'createdCell': (td, cellData, rowData, row, col) => {
          $(td).addClass('text-nowrap')
          payrollSheetCellWithState(td, rowData, 17)
        }
      }
    ],
    "rowCallback": (row, data, dataIndex) => {
      $(row).find('.salary-sheet-actions-dropdown').closest('td').addClass('disable-propagation')
    }
  });
  debounceSearch('#payroll-sheet')
}

const initialize_manual_payment_toggle = () => {
  $('.manual-payment-toggle').bootstrapToggle({
    on: 'Complete Amount',
    off: 'Partial Amount',
    width: '50%',
    size: 'small',
    height: 'match-parent',
    style: 'rounded'
  })
}

const attribute_state_icon = (state_mapping, attribute_position, ) => {
  if (state_mapping['locked'].includes(attribute_position)) {
    return "<i class='fas fa-sm fa-lock px-2 disable-propagation'></i>";
  } else if (state_mapping['ungenerated'].includes(attribute_position)) {
    return "<i class='fas fa-sm fa-exclamation-triangle px-2 disable-propagation'></i>";
  }
};

const payrollSheetCellWithState = (td, rowData, columnNumber) => {
  if(rowData[19]){
    if (rowData[19].attributes_state_mapping['updated'].includes(columnNumber)){
      $(td).addClass('bg-light-orange')
    }
    if (!rowData[19].completely_locked && !rowData[19].completely_ungenerated){
      $(td).append(attribute_state_icon(rowData[19].attributes_state_mapping, columnNumber));
    }
  }
  if (columnNumber === 14 || columnNumber === 17) {
    $(td).addClass('disable-propagation')
  }
}

$(document).on('show.bs.modal', '.salary-operations', (e)=> {
  $(e.target).find('.list-group-item:first').click();
});

$(document).on('change', '#salary-operations-settings .bootstrap-toggle-wrapper input[type="checkbox"]', (e)=> {
  toggle = $(e.target)
  var value = toggle.prop('checked') ? 1 : 0;
  toggle.val(value);
  updateSubmitButtonDisableStatus(toggle);
});

$(document).on('change', '#salary-status-filter', () => { filters_payroll_sheet() });
$(document).on('click', '.salary-sheet-notifications-modal', showSalarySheetNotificationsModal);

$(document).on('click', '#bulk-resolve-btn', (e) => {
  let selectedNotifications = $("input.mark-as-read-checkbox:checked").map(function(){return $(this).attr("notification_id"); }).get();
  if (selectedNotifications.length > 0){
    markAsResolve(selectedNotifications)
  }
});

$(document).on('click', '.mark-as-resolved', (e) => {
  let notification_id = e.currentTarget.getAttribute('notification_id');
  if (window.location.pathname == '/employee_salaries/employee_salaries_sheet') {
    markAsResolve(notification_id);
  }
});

$(document).on('change', '.notification-filter', (e) => {
  let filter = e.target.value;
  $('#bulk-resolve').toggle(filter !== 'resolved');
  loadSalarySheetNotifications(filter);
});

function showSalarySheetNotificationsModal() {
  $('#salary-sheet-notifications-modal').modal('show');
  if ($('#select-notification-filter').val()) {
    loadSalarySheetNotifications($('#select-notification-filter').val());
  }
}

function loadSalarySheetNotifications(filter) {
  $.ajax({
    type: 'GET',
    url: '/employee_salaries/salary_sheet_notifications',
    dataType: 'JSON',
    data: { filter: filter },
    success(response){
      $('.salary-sheet-notifications').html(response.html);
      if(response.count == 0){
        $('#bulk-resolve').hide();
        $('.salary-sheet-notifications').html(`<div class='text-center py-2'> No result found </div>`);
      }
    }
  });
}

function markAsResolve(notification_ids) {
  $.ajax({
    type: 'PUT',
    url: `/employee_salaries/resolve_selected_notifications`,
    dataType: 'JSON',
    data: {notification_ids: notification_ids},
    success(response){
      $('#bulk-resolve-btn').prop('disabled', 1);
      loadSalarySheetNotifications($('#select-notification-filter').val());
    }
  });
}

$(document).on('click', '#generate-salaries-btn, #confirmModalConfirmButton', (e) => {
  $('.notification-alert-container').hide()
});

$(document).on('change', 'input.mark-as-read-checkbox', (e) => {
  var anyCheckboxChecked = $('input.mark-as-read-checkbox:checked').length > 0;
  $('#bulk-resolve-btn').prop('disabled', !anyCheckboxChecked);
});

const updateSubmitButtonDisableStatus = (toggle) => {
  let form = toggle.closest('form');
  let anyToggleChecked = form.find(".entity-generation-toggles .bootstrap-toggle-wrapper input:checked").length > 0;
  form.find("input[type='submit']").prop("disabled", !anyToggleChecked);
}

$(document).on('ajax:error ajax:success', '.add-review-form', (e) => {
  window.location.href = '/employee_salaries/employee_salaries_sheet';
});
